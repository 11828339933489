import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'; // Import the CSS file for the popup styles
import '../../../assets/css/Tooltip.css';
import { RiInformationLine } from 'react-icons/ri';

const InfoTooltip = ({ headText, text }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'flex-start',
          cursor: 'pointer',
        }}
      >
        <div style={{ position: 'relative' }}>
          <RiInformationLine
            color="#306cca"
            size="20px"
            style={{
              // cursor: 'pointer',
              marginTop: '3px',
              marginLeft: '3px',
              // position: 'absolute',
            }}
          />
          {isOpen && (
            <div
              className="tooltip-text"
              // className='toot'
              style={{
                backgroundColor: '#306cca',
                color: '#ffffff',
                fontSize: '12px',
                // padding: '14px',
                left: '-55px',
                top: '25px',
                whiteSpace: 'collapse',
                width: '150px',
                // position: 'absolute',
              }}
            >
              <p style={{ margin: 0 }}>
                <strong>{headText} </strong>
              </p>
              <p style={{ margin: 0 }}>{text}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoTooltip;
