import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import styled from '@emotion/styled';
import { Checkbox } from '@material-ui/core';
import ErrorIcon from '../../../assets/images/errorYellowCircle.png';
import SuccessIcon from '../../../assets/images/greenSuccessBig.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import Tick from '../../../assets/images/checkmark.png';
import { logoutHandler } from '../../../utility/utility';
import PreLoader from '../../../common/component/preLoader/PreLoader';

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const statesList = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
];

const overlayStyle = { background: 'rgba(0,0,0,0.9)' };
const contentStyle = {
  width: 'auto',
  padding: '32px',
  borderRadius: '4px',
  boxShadow: '0 0 6px 0 #00000029',
  backgroundColor: '#fff',
};

const Head = styled.p`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  letter-spacing: normal;
  text-align: center;
  color: #454545;
`;
const SubHead = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #454545;
  margin-bottom: 8px;
`;
const HeadInfo = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
  margin-bottom: 18px;
`;

const ButtonType1 = styled.button`
  width: 336px;
  height: 48px;
  border-radius: 4px;
  background-color: #ea4b4b;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  border: none;
`;

const ButtonType2 = styled.p`
  width: 336px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.13;
  letter-spacing: normal;
  text-align: center;
  color: #454545;
  text-decoration: underline;
  margin-top: 24px;
  cursor: pointer;
`;

const IDValidated = ({ id }) => {
  return (
    <p style={{ color: '#05aa3f', fontWeight: '500', fontSize: '14px' }}>
      <img src={Tick} style={{ width: '14px', marginRight: '4px' }} />
      {`${id} Validated`}
    </p>
  );
};

export function StepCounter({ currentStep }) {
  const steps = [1, 2, 3, 4];
  const labels = [
    'Company Info ',
    'Billing Info',
    'Verify Company',
    'Verify Director',
  ];

  if (['3a', '3b', '3c'].includes(currentStep)) {
    currentStep = '3';
  }
  if (currentStep === 's' || currentStep === 'f') return null;
  return (
    <div className="steps" style={{ width: '328px' }}>
      {steps.map((step, index) => (
        <div
          className={`step ${step <= currentStep ? 'active' : ''}`}
          key={index}
        >
          <div className="outer-circle">
            <div className="inner-circle">
              {step < currentStep ? '\u2713' : step}
            </div>
          </div>
          <div className="step-label">{labels[index]}</div>
        </div>
      ))}
    </div>
  );
}

export default function SelfKYCPage(props) {
  const history = useHistory(); // Get the history object
  const [step, setStep] = useState(props?.stage || '1');
  const [companyDetails, setcompanyDetails] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [type, setType] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [CIN, setCIN] = useState(null);
  const [GST, setGST] = useState(null);
  const [directorsList, setDirectorsList] = useState([]);
  const [directorSelected, setDirectorSelected] = useState(null);
  const [aadhaar, setAadhaar] = useState(null);
  const [transId, setTransId] = useState(null);
  const [OTP, setOTP] = useState(null);
  const [disableActions] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [isCallbackRequested, setIsCallbackRequested] = useState(false);
  const [retries, setRetries] = useState(0);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [orgCity, setOrgCity] = useState(null);
  const [orgState, setOrgState] = useState(null);
  const [orgCountry, setOrgCountry] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [billingDataUpdated, setBillingDataUpdated] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(600);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isKycCompleted, setIsKycCompleted] = useState(true);
  let currentStep = null;

  const fetchKycStatus = async () => {
    console.log('test kycstatus');
    setProcessing(true);
    await GET(`${orgUrl}v1/organisation/basic/details`, {
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setProcessing(false);
        console.log('test kycstatus', res?.organisation?.is_kyc_completed);
        if (res?.organisation?.is_kyc_completed === true) {
          setIsKycCompleted(true);
        }
        if (res?.organisation?.is_kyc_completed === false) {
          setIsKycCompleted(false);
        }
      })
      .catch((err) => {
        console.log('err');
      });
  };

  useEffect(() => {
    fetchKycStatus();
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  useEffect(() => {
    let countdown;
    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
      setOtpSent(false);
    }
    return () => clearInterval(countdown); // Cleanup on unmount
  }, [isTimerActive, timer]);

  useEffect(() => {
    if (localStorage.getItem('is_kyc_callback_requested') === 'true') {
      setStep('f');
      setIsCallbackRequested(true);
    }
  }, []);

  useEffect(() => {
    if (step === 's') {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [step, history]);

  function validateInputs(...inputs) {
    for (const input of inputs) {
      if (input === null || input === '') {
        return toast.error(
          'Invalid input: Input cannot be null or empty string.'
        );
      }
    }
    return true;
  }

  const verifyKYC = async () => {
    try {
      setProcessing(true);
      validateInputs(
        companyName,
        address1,
        address2,
        orgCity,
        orgState,
        orgCountry,
        postalCode
      );
      PUT(`${orgUrl}v1/organisation`, null, {
        is_self_kyc: true,
        is_kyc_completed: true,
        organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
        invoiceDetails: {
          name: companyName,
          cname: companyName,
          address: {
            street: address1 + ' ' + address2,
            city: orgCity,
            state: orgState,
            country: orgCountry,
            pinCode: postalCode,
          },
          gstin: GST,
        },
      });
      await verifyOrganisationSubscriptionStatus();
    } catch (err) {
      setProcessing(false);
      toast.error(err?.response?.data?.error?.reason);
    }
  };

  const verifyOrganisationSubscriptionStatus = async () => {
    let attempts = 0;
    const maxAttempts = 10;
    const interval = 10000; // 10 seconds

    while (attempts < maxAttempts) {
      attempts++;
      try {
        const resp = await GET(
          `${orgUrl}v1/organisation/payment-confirmation`,
          {
            organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          }
        );

        if (resp?.data?.response?.paymentSuccess) {
          setProcessing(false);
          setStep('s');
          toast.success('Organisation KYC is updated successfully.');
          return; // Exit the function if the response is successful
        } else {
          console.log(
            `Attempt ${attempts} failed. Retrying in ${
              interval / 1000
            } seconds...`
          );
        }
      } catch (error) {
        console.log(
          `Attempt ${attempts} failed due to error: ${error}. Retrying in ${
            interval / 1000
          } seconds...`
        );
      }

      if (attempts < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, interval)); // Wait for the interval before the next attempt
      }
    }
    if (attempts === maxAttempts) {
      setProcessing(false);
      setStep('f');
      console.error('Error: Maximum attempts reached.');
    }
    console.error('Error: Maximum attempts reached.');
  };

  const verifyDetails = async () => {
    try {
      if (processing) return;
      const data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };
      if (step === '1') {
        if (!type || !industry) return toast.error('select options');
        return setStep('2');
      }
      if (step === '3a') {
        if (!CIN || CIN === '') return toast.error('provide CIN');
        data.docType = 'CIN';
        data.docNumber = CIN;
      }
      if (step === '3b') {
        if (!directorSelected) {
          throw toast.error('Select a director');
        }
        return setStep('4');
      }
      if (step === '3c') {
        if (!directorSelected) {
          throw toast.error('Select a director');
        }
        return setStep('4');
      }
      if (step === '4') {
        data.docType = 'VERIFY';
        data.otp = OTP;
        data.transId = transId;
        data.isTrust = type === 'Trust';
        data.isProprietor = type === 'Sole Proprietorship';
        data.selectedDirectorName = directorSelected;
      }

      setProcessing(true);
      const res = await POST(`${orgUrl}kyc`, data);
      // const res = {};
      setcompanyDetails(res?.data?.response?.msg);
      setDirectorsList(res?.data?.response?.msg?.proprietor_name);
      if (res?.data?.response?.msg?.MasterDirector) {
        setDirectorsList(res?.data?.response?.msg?.MasterDirector);
      }

      setProcessing(false);

      if (res.data.response.status !== 1) {
        return toast.error(res?.data?.response?.msg);
      }

      if (step === '3a') return setStep('3b');
      if (step === '3b') return setStep('4');
      if (step === '3c') return setStep('4');
      if (step === '4') {
        verifyKYC();
      }
    } catch (err) {
      if (step === '4') {
        if (retries >= 3) {
          setStep('f');
        } else {
          setRetries(retries + 1);
          toast.error(err?.response?.data?.error?.reason);
        }
      } else {
        toast.error(err?.response?.data?.error?.reason);
      }
      setProcessing(false);
    }
  };

  const fetchVerificationOtp = async () => {
    try {
      if (processing) return;
      if (!aadhaar || aadhaar === '')
        return toast.error('Provide Aadhaar Card Details');
      setProcessing(true);
      const data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        docType: 'AADHAR',
        docNumber: aadhaar,
      };
      const res = await POST(`${orgUrl}kyc`, data);

      setTransId(res?.data?.response?.tsTransId);
      setProcessing(false);
      setOtpSent(true);
      setTimer(600); // Reset timer to 600 seconds / 10 min
      setIsTimerActive(true); // Activate the countdown
      toast.success('OTP sent');
    } catch (err) {
      toast.error(err?.response?.data?.error?.reason);
      setProcessing(false);
    }
  };

  const verifyBillingInfo = async () => {
    try {
      if (processing) return;
      if (
        !address1 ||
        !orgCity ||
        !orgState ||
        !orgCountry ||
        !postalCode ||
        !companyName
      )
        return toast.error('Provide Billing Details');
      // if (['Sole Proprietorship'].includes(type) && !GST) {
      //   return toast.error('Provide GST number');
      // }
      setProcessing(true);
      const billingData = {
        organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
        is_kyc_completed: false,
        invoiceDetails: {
          name: companyName,
          cname: companyName,
          address: {
            street: address1 + ' ' + address2,
            city: orgCity,
            state: orgState,
            country: orgCountry,
            pinCode: postalCode,
          },
          gstin: GST,
        },
      };

      if (!billingDataUpdated) {
        await PUT(`${orgUrl}v1/organisation`, null, billingData);
        setBillingDataUpdated(true);
      }

      if (GST && GST !== '') {
        const res = await POST(`${orgUrl}kyc`, {
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          docType: 'GST',
          docNumber: GST,
        });
        setcompanyDetails(res?.data?.response?.msg);
        setDirectorsList(res?.data?.response?.msg?.proprietor_name);
        if (res.data.response.status !== 1) {
          setProcessing(false);
          return toast.error(res?.data?.response?.msg);
        }
        setStep('3c');
      } else if (['Trust', 'Sole Proprietorship'].includes(type)) {
        setStep('4');
      } else {
        setStep('3a');
      }
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      toast.error(err?.response?.data?.error?.reason || 'An Error occured');
    }
  };

  const requestCallback = async () => {
    try {
      if (processing) return;
      setProcessing(true);
      const data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };

      const res = await POST(`${orgUrl}kyc/request-call-back`, data);
      localStorage.setItem('is_kyc_callback_requested', true);
      console.log('res', res?.data?.response);
      setProcessing(false);
      setIsCallbackRequested(true);
    } catch (err) {
      console.log('err');
      setProcessing(false);
    }
  };

  const backHandler = () => {
    if (processing) return;
    if (
      step === '4' &&
      ['OPC', 'Private Limited Company', 'LLP', 'NGO', 'Societies'].includes(
        type
      )
    ) {
      setStep('3b');
    }
    if (step === '4' && ['Trust'].includes(type)) {
      setStep('2');
    }
    if (step === '4' && ['Sole Proprietorship'].includes(type)) {
      setStep('3c');
    }
    if (step === '3b') {
      setStep('3a');
    }
    if (step === '3a') {
      setStep('2');
    }
    if (step === '3c') {
      setStep('2');
    }
    if (step === '2') {
      setStep('1');
    }
  };

  if (step === '1') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <Head>Welcome to Alohaa!</Head>
        <SubHead>Complete your KYC to get access to 14 day free access</SubHead>
        <HeadInfo>
          KYC is mandated by the government and is a compulsory step
        </HeadInfo>

        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '8px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Registered Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <select
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            border: 'solid 1px #d1d3d4',
            marginBottom: '8px',
          }}
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option default hidden>
            Business registration type
          </option>
          <option value="OPC">OPC (One Person Company)</option>
          <option value="Private Limited Company">
            Private Limited Company
          </option>
          <option value="LLP">LLP (Limited Liability Partnership)</option>
          <option value="NGO">NGO (Non-Governmental Organization)</option>
          <option value="Trust">Trust</option>
          <option value="Societies">Societies</option>
          <option value="Sole Proprietorship">Sole Proprietorship</option>
        </select>

        <select
          style={{
            width: '100%',
            height: '42px',
            marginBottom: '24px',
            borderRadius: '4px',
            border: 'solid 1px #d1d3d4',
          }}
          value={industry}
          placeholder={'Industry'}
          onChange={(e) => {
            setIndustry(e.target.value);
          }}
        >
          <option default hidden>
            Industry
          </option>
          <option value="Logistics">Logistics</option>
          <option value="Manufacturing">Manufacturing</option>
          <option value="Education">Education</option>
          <option value="NGO & Social Enterprises">
            NGO & Social Enterprises
          </option>
          <option value="BFSI & NBFC">BFSI & NBFC</option>
          <option value="Marketing & Advertising">
            Marketing & Advertising
          </option>
          <option value="FMCG">FMCG</option>
          <option value="Hospitality">Hospitality</option>
          <option value="IT Services">IT Services</option>
          <option value="Hyperlocal">Hyperlocal</option>
          <option value="E-Commerce">E-Commerce</option>
          <option value="Food & Beverage">Food & Beverage</option>
          <option value="HR & Recruitment">HR & Recruitment</option>
          <option value="Healthcare">Healthcare</option>
          <option value="Real Estate">Real Estate</option>
          <option value="Marketplaces">Marketplaces</option>
          <option value="Travel">Travel</option>
          <option value="Others">Others</option>
        </select>

        <ButtonType1 onClick={verifyDetails}>Next</ButtonType1>
        {/* <ButtonType2 onClick={logoutHandler}>Logout</ButtonType2> */}
      </div>
    );
  }

  if (step === '2') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <Head>Billing information</Head>
        <SubHead>
          Information gathered here will reflect on your invoices
        </SubHead>
        <HeadInfo>{companyName}</HeadInfo>
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '8px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Address line 1"
          key="addr1"
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
        />{' '}
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '8px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Address line 2"
          value={address2}
          onChange={(e) => setAddress2(e.target.value)}
        />{' '}
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '8px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="City"
          value={orgCity}
          onChange={(e) => setOrgCity(e.target.value)}
        />{' '}
        <select
          style={{
            width: '100%',
            height: '42px',
            marginBottom: '8px',
            borderRadius: '4px',
            border: 'solid 1px #d1d3d4',
          }}
          value={orgState}
          placeholder={'State'}
          onChange={(e) => {
            setOrgState(e.target.value);
          }}
        >
          <option default hidden>
            Select State
          </option>
          {statesList.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '8px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Country"
          value={orgCountry}
          onChange={(e) => setOrgCountry(e.target.value)}
        />{' '}
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '8px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Postal code"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />{' '}
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '24px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="GSTIN"
          value={GST}
          onChange={(e) => setGST(e.target.value)}
        />
        <p className="PopupNote" style={{ color: '#ed3833' }}>
          NOTE: Provide the correct GSTIN to claim ITC, as it can't be changed
          later.
        </p>
        <ButtonType1 onClick={verifyBillingInfo}>Next</ButtonType1>
        <ButtonType2 onClick={backHandler}>Back</ButtonType2>
      </div>
    );
  }

  if (step === '3a') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <SubHead>
          Validate <strong>CIN</strong>
        </SubHead>
        <input
          style={{
            width: '100%',
            height: '48px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '16px',
            marginTop: '16px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Enter CIN"
          value={CIN}
          onChange={(e) => setCIN(e.target.value)}
        />
        <ButtonType1 onClick={verifyDetails}>Validate</ButtonType1>
        <ButtonType2 onClick={backHandler}>Back</ButtonType2>
      </div>
    );
  }

  if (step === '3b') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <SubHead>
          Validate <strong>CIN</strong>
        </SubHead>
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder={CIN}
          value={CIN}
          disabled={true}
        />
        <IDValidated id="CIN" />

        <p
          style={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#454545',
            marginBottom: '16px',
          }}
        >
          We’ve identified your company and director(s)
        </p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#939598',
            margin: '0',
          }}
        >
          Company name:
        </p>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '500',
            color: '#05aa3f',
            marginBottom: '24px',
          }}
        >
          {companyDetails?.company_name || ''}
        </p>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '500',
            color: '#454545',
          }}
        >
          We’ll need to verify the Aadhaar details of one of your directors
          listed below. Select a directors name, and keep their Aadhaar details
          handy.
        </p>
        <div style={{ marginBottom: '24px' }}>
          {directorsList?.length > 0 &&
            directorsList?.map((item, index) => (
              <div key={index}>
                <Checkbox
                  checked={item.name === directorSelected}
                  onClick={(e) => setDirectorSelected(item?.name)}
                />
                <label for="vehicle1">{item?.name}</label>
                <br />
              </div>
            ))}
        </div>
        <ButtonType1 onClick={verifyDetails}>Next</ButtonType1>
        <ButtonType2 onClick={backHandler}>Back</ButtonType2>
      </div>
    );
  }

  if (step === '3c') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <SubHead>
          Validate <strong>GSTIN</strong>
        </SubHead>
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder={GST}
          value={GST}
          disabled={true}
        />
        <IDValidated id="GST" />
        <p
          style={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#454545',
            marginBottom: '16px',
          }}
        >
          We’ve identified your company and director(s)
        </p>
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#939598',
            margin: '0',
          }}
        >
          Company name:
        </p>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '500',
            color: '#05aa3f',
            marginBottom: '24px',
          }}
        >
          {companyDetails?.['Trade Name'] || ''}
        </p>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '500',
            color: '#454545',
          }}
        >
          We’ll need to verify the Aadhaar details of one of your directors
          listed below. Select a directors name, and keep their Aadhaar details
          handy.
        </p>
        <div style={{ marginBottom: '24px' }}>
          {directorsList?.length > 0 &&
            directorsList?.map((item, index) => (
              <div key={index}>
                <Checkbox
                  checked={item === directorSelected}
                  onClick={(e) => setDirectorSelected(item)}
                />
                <label for="vehicle1">{item}</label>
                <br />
              </div>
            ))}
        </div>
        <ButtonType1 onClick={verifyDetails}>Next</ButtonType1>
        <ButtonType2 onClick={backHandler}>Back</ButtonType2>
      </div>
    );
  }

  if (step === '4') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <SubHead>
          Validate <strong>Aadhaar</strong>
        </SubHead>
        <HeadInfo>Director selected - {directorSelected}</HeadInfo>
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '16px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Enter Aadhaar"
          value={aadhaar}
          onChange={(e) => setAadhaar(e.target.value)}
        />
        <p
          style={{
            fontSize: '16px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '2.13',
            textAlign: 'center',
            color: '#07aa3f',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={fetchVerificationOtp}
          disabled={otpSent}
        >
          {otpSent ? `Resend OTP in ${formatTime(timer)}` : 'Request OTP'}
        </p>
        <input
          style={{
            width: '100%',
            height: '42px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            marginBottom: '40px',
            border: '1px solid rgb(209, 211, 212)',
          }}
          placeholder="Enter OTP"
          value={OTP}
          onChange={(e) => setOTP(e.target.value)}
        />
        {retries > 0 ? (
          <ButtonType1 onClick={verifyDetails}>
            Retry {retries > 0 ? retries : null}
          </ButtonType1>
        ) : (
          <ButtonType1 onClick={verifyDetails}>Validate OTP</ButtonType1>
        )}
        <ButtonType2 onClick={backHandler}>Back</ButtonType2>
      </div>
    );
  }

  if (step === 's') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <div
          style={{ width: '100%', margin: '0 auto 40px', textAlign: 'center' }}
        >
          <img src={SuccessIcon} style={{ width: '100px' }} />
        </div>
        <SubHead style={{ color: '#07aa3f' }}>Verification successful</SubHead>
        <p
          style={{
            color: '#939598',
            width: '192px',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          Redirecting you to the dashboard in 5 seconds
        </p>
      </div>
    );
  }

  if (step === 'f') {
    currentStep = (
      <div style={{ width: '336px' }}>
        <div
          style={{ width: '100%', margin: '0 auto 40px', textAlign: 'center' }}
        >
          <img src={ErrorIcon} />
        </div>
        <SubHead style={{ color: '#f8bb44' }}>Verification failed</SubHead>
        <p
          style={{
            color: '#939598',
            width: '240px',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          We noticed data mismatch in the details you provided <br />
          Our team will verify your documents manually.
        </p>
        {isCallbackRequested ? (
          <p
            style={{
              color: '#23b224',
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: '40px',
            }}
          >
            CALLBACK REQUESTED
          </p>
        ) : (
          <ButtonType1
            style={{ backgroundColor: '#000', marginTop: '40px' }}
            onClick={requestCallback}
          >
            REQUEST CALLBACK
          </ButtonType1>
        )}
      </div>
    );
  }

  if (isKycCompleted && !props?.stage) return null;

  return (
    <div>
      {processing && <PreLoader />}
      <Popup
        open={true}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle, contentStyle }}
      >
        <div style={{ marginBottom: '50px' }}>
          <StepCounter currentStep={step} />
        </div>
        {currentStep}
      </Popup>
    </div>
  );
}
